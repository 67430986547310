/* Header.css */

.sticky-header {
    z-index: 1000 !important;
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #002855;
    border-bottom: 2px solid #001d3d;
    color: #fff;
    top: 0;
    transition: background-color 0.3s ease;
}

.header-container.scrolled {
    background-color: rgba(0, 40, 85, 0.8); /* Slightly transparent background */
}

.logo-title {
    display: flex;
    align-items: center;
}

.logo {
    height: 100px;
    margin-right: 15px;
}

.title {
    margin: 0;
    font-size: 1.8em;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    font-weight: bold;
}

nav {
    position: relative;
}

.main-menu {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    transition: background-color 0.3s ease;
}

.main-menu > li {
    margin: 0 15px;
    position: relative;
}

.main-menu > li > a {
    font-size: 1em;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
}

.main-menu > li > a:hover,
.main-menu > li > a:focus {
    color: #cce7ff;
}

/* Responsive styles */
.menu-toggle {
    display: none;
    background: none;
    border: none;
    font-size: 2em;
    color: #fff;
    cursor: pointer;
}

@media (max-width: 930px) {
    .title {
        font-size: 1.6em;
        font-weight: bold;
    }
    .menu-toggle {
        display: block;
    }
    .main-menu {
        display: none;
        flex-direction: column;
        background-color: #002855;
        position: fixed;
        top: 120px;
        right: 0;
        left: 0;
        text-align: right;
        z-index: 999;
        padding: 20px;
        transition: background-color 0.3s ease;
        border-bottom: 2px solid #001d3d;
    }
    .main-menu.open {
        display: flex;
    }
    .main-menu.scrolled-menu {
        background-color: rgba(0, 40, 85, 0.8);
    }
    .main-menu > li {
        margin: 0;
    }
    .main-menu > li:last-child {
        border-bottom: none;
    }
    .main-menu > li > a {
        display: block;
    }
}
