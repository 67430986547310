/* Contact.css */

.contactContainer {
    text-align: center;
    padding: 2rem;
    background-color: #e6f0fa;
    color: #002855;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
}

.contactContainer h1,
.contactContainer h2 {
    color: #001d3d;
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
}

.contactContainer p {
    color: #002855;
    line-height: 1.6;
    margin-bottom: 20px;
}

.contactForm {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactForm form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px; /* Limite la largeur du formulaire */
}

.contactForm label {
    margin-top: 10px;
    margin-bottom: 5px;
    color: #002855;
    font-size: 1em;
}

.contactForm input[type='text'],
.contactForm input[type='email'],
.contactForm textarea {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
    width: 100%;
    box-sizing: border-box;
}

.contactForm textarea {
    height: 200px;
    resize: vertical;
}

.contactForm button {
    padding: 10px 20px;
    background-color: #004085;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
}

.contactForm button:hover {
    background-color: #002855;
}

.directContact p strong {
    color: #001d3d;
}

.directContact a {
    color: #004085;
    text-decoration: none;
}

.directContact a:hover {
    text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .contactContainer {
        padding: 30px 20px;
    }
}

@media (max-width: 992px) {
    .contactContainer {
        padding: 25px 15px;
    }

    .contactContainer h1,
    .contactContainer h2 {
        font-size: 1.8rem;
    }

    .contactForm label {
        font-size: 1rem;
    }

    .contactForm input[type='text'],
    .contactForm input[type='email'],
    .contactForm textarea {
        font-size: 1rem;
    }

    .contactForm button {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .contactContainer {
        padding: 20px 10px;
    }

    .contactContainer h1,
    .contactContainer h2 {
        font-size: 1.6rem;
    }

    .contactForm label {
        font-size: 1rem;
    }

    .contactForm input[type='text'],
    .contactForm input[type='email'],
    .contactForm textarea {
        font-size: 1rem;
    }

    .contactForm button {
        font-size: 1rem;
    }
}

@media (max-width: 576px) {
    .contactContainer {
        padding: 15px 5px;
    }

    .contactContainer h1,
    .contactContainer h2 {
        font-size: 1.4rem;
    }

    .contactForm label {
        font-size: 1rem;
    }

    .contactForm input[type='text'],
    .contactForm input[type='email'],
    .contactForm textarea {
        font-size: 1rem;
    }

    .contactForm button {
        font-size: 1rem;
    }
}
