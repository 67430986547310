/* Footer.css */

.footer {
    background-color: #001d3d; /* Darker blue */
    color: #fff; /* White text */
    padding: 20px; /* Increased padding for better spacing */
    text-align: center; /* Centered text */
    border-top: 2px solid #e0e0e0; /* Light grey top border */
    font-size: 0.9em; /* Slightly smaller font size */
    position: relative;
    bottom: 0;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for a slight lift effect */
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer p {
    margin: 0; /* Remove default margin */
}

.footer-link {
    color: #fff; /* White text */
    text-decoration: none; /* Remove underline */
    margin-top: 10px; /* Space above the link */
    display: inline-block; /* Ensures margin works */
}

.footer-link:hover {
    text-decoration: underline; /* Underline on hover */
}

.social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px; /* Space above the icons */
}

.social-icons a {
    color: #fff; /* White color for the icons */
    font-size: 1.5em; /* Adjust the size as needed */
    margin: 0 10px; /* Space between the icons */
    transition: color 0.3s ease;
}

.social-icons a:hover {
    color: #cce7ff; /* Light blue color on hover */
}

/* Responsive styles */
@media (max-width: 576px) {
    .social-icons a {
        font-size: 1.2em; /* Smaller size for small screens */
        margin: 0 5px; /* Reduce space between icons */
    }
}
