/* ServiceCard.css */

.serviceCard {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    background-color: #fff;
    color: #002855;
    max-width: 350px;
    text-align: left;
}

.serviceCard:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.serviceCardImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
}

.serviceCard h3 {
    color: #001d3d;
    margin-bottom: 15px;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
}

.serviceCard p {
    line-height: 1.6;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .serviceCard {
        padding: 18px;
        margin: 8px;
        max-width: 300px;
    }

    .serviceCard h3 {
        font-size: 1.4rem;
    }

    .serviceCard p {
        font-size: 0.95rem;
    }
}

@media (max-width: 992px) {
    .serviceCard {
        padding: 16px;
        margin: 6px;
        max-width: 280px;
    }

    .serviceCard h3 {
        font-size: 1.3rem;
    }

    .serviceCard p {
        font-size: 0.9rem;
    }
}

@media (max-width: 768px) {
    .serviceCard {
        padding: 14px;
        margin: 4px;
        max-width: 260px;
    }

    .serviceCardImage {
        height: 180px;
    }

    .serviceCard h3 {
        font-size: 1.2rem;
    }

    .serviceCard p {
        font-size: 0.85rem;
    }
}

@media (max-width: 576px) {
    .serviceCard {
        padding: 12px;
        margin: 2px;
        max-width: 240px;
    }

    .serviceCardImage {
        height: 160px;
    }

    .serviceCard h3 {
        font-size: 1.1rem;
    }

    .serviceCard p {
        font-size: 0.9rem;
    }
}
