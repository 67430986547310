/* ServicesSection.css */

.servicesSection {
    padding: 40px 20px;
    background-color: #e6f0fa;
    color: #002855;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
}

.servicesSection h2 {
    color: #001d3d;
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
}

.servicesSection p {
    margin-bottom: 20px;
    color: #002855;
    font-size: 1.1rem;
    font-family: 'Open Sans', sans-serif;
}

.carouselItem {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.servicesGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .servicesSection h2 {
        font-size: 1.8rem;
    }

    .servicesSection p {
        font-size: 1rem;
    }
}

@media (max-width: 992px) {
    .servicesSection h2 {
        font-size: 1.6rem;
    }

    .servicesSection p {
        font-size: 1rem;
    }

    .react-multi-carousel-arrow--left {
        left: -40px;
    }

    .react-multi-carousel-arrow--right {
        right: -40px;
    }
}

@media (max-width: 768px) {
    .servicesSection {
        padding: 20px 15px;
        margin: 15px;
    }

    .servicesSection h2 {
        font-size: 1.5rem;
    }

    .servicesSection p {
        font-size: 1rem;
    }

    .react-multi-carousel-arrow--left {
        left: -30px;
    }

    .react-multi-carousel-arrow--right {
        right: -30px;
    }
}

@media (max-width: 576px) {
    .servicesSection {
        padding: 15px 10px;
        margin: 10px;
    }

    .servicesSection h2 {
        font-size: 1.4rem;
    }

    .servicesSection p {
        font-size: 1rem;
    }

    .react-multi-carousel-arrow--left {
        left: -20px;
    }

    .react-multi-carousel-arrow--right {
        right: -20px;
    }
}
