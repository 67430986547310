/* HeroSection.css */

.heroSection {
    text-align: center;
    padding: 2rem;
    background-color: #e6f0fa;
    color: #002855;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
}

.heroTitle {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    font-family: 'Roboto', sans-serif;
    color: #001d3d;
    margin-top: 0px;
}

.heroText {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.6;
    color: #002855;
}

.heroText strong {
    color: #001d3d;
}

.heroLink {
    display: inline-block;
    background-color: #004085;
    color: #fff;
    padding: 1rem 2rem;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.heroLink:hover {
    background-color: #002855;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .heroTitle {
        font-size: 2.2rem;
    }

    .heroText {
        font-size: 1.1rem;
    }

    .heroLink {
        padding: 0.9rem 1.8rem;
        font-size: 1.1rem;
    }
}

@media (max-width: 992px) {
    .heroTitle {
        font-size: 1.8rem;
    }

    .heroText {
        font-size: 1rem;
    }

    .heroLink {
        padding: 0.8rem 1.6rem;
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .heroSection {
        padding: 1.5rem;
        margin: 15px;
    }

    .heroTitle {
        font-size: 1.8rem;
    }

    .heroText {
        font-size: 0.9rem;
    }

    .heroLink {
        padding: 0.7rem 1.4rem;
        font-size: 0.9rem;
    }
}

@media (max-width: 576px) {
    .heroSection {
        padding: 1rem;
        margin: 10px;
    }

    .heroTitle {
        font-size: 1.5rem;
    }

    .heroText {
        font-size: 1rem;
    }

    .heroLink {
        padding: 0.6rem 1.2rem;
        font-size: 1rem;
    }
}
