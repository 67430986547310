html,
body {
    font-family: 'Open Sans', sans-serif;
    background-color: #f4f4f4;
    color: #333;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    height: 100%;
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative; /* Contexte de positionnement pour sticky header */
    overflow: clip; /* Empêche le débordement horizontal */
}

.main-content {
    flex: 1;
    overflow: auto;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px; /* Ajout de padding par défaut */
    width: 100%;
}

a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
}

a:hover,
a:focus {
    color: #0056b3;
    text-decoration: underline;
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
    .main-content {
        padding: 15px;
    }
}

@media (max-width: 992px) {
    .main-content {
        padding: 10px;
    }
}

@media (max-width: 768px) {
    .main-content {
        padding: 10px;
        max-width: 100%; /* Assurez-vous que le contenu occupe toute la largeur */
    }
}

@media (max-width: 576px) {
    .main-content {
        padding: 5px; /* Réduire encore le padding pour les très petits écrans */
        margin: 0; /* Supprimer les marges externes */
        max-width: 100%;
    }
}
